export function LogoutUser(t) {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
}

// export function getName() {
//   const user = getUser();
//   return user && user.FULLNAME;
// }

export function getId() {
  //   const user = getUser();
  //   return user && user.ID;
  return 1;
}

export function isLoggedin() {
  const token = getToken();
  return token ? true : false;
}

export function getToken() {
  //   const token = localStorage.getItem("token");
  const token = "faketokenreturn";
  return token;
}

export function setToken(t) {
  localStorage.setItem("token", t);
}
