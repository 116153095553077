import DataTable from "react-data-table-component";
import config from "../config.json";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  FaFilePdf,
  FaExternalLinkAlt,
  FaExchangeAlt,
  FaPrint,
} from "react-icons/fa";

let PDFURL = `${config.api_url}/pdf?url=${config.front_url}/report/pcr`;
let PRINTURL = `${config.front_url}/report/pcr`;
const ts = Math.floor(Date.now() / 1000);

function Tests({ tests, getResults, onTestReset, Patient }) {
  const columns = [
    {
      name: "TESTNAME",
      selector: (row) => row.TESTNAME,
      sortable: false,
    },
    {
      cell: (row) => (
        <>
          {row.TESTCODE === "3612" && (
            <>
              <button
                title="Report"
                className="btn btn-success m-1"
                onClick={() =>
                  window.open(
                    `${PDFURL}/${parseInt(row.PID)}/${row.TESTID}&t=${ts}`,
                    "_blank"
                  )
                }
                id={row.ID}
              >
                <FaFilePdf />
              </button>
              <button
                title="Report"
                className="btn btn-success m-1"
                onClick={() =>
                  window.open(
                    `${PRINTURL}/${parseInt(row.PID)}/${row.TESTID}?t=${ts}`,
                    "_blank"
                  )
                }
                id={row.ID}
              >
                <FaPrint />
              </button>
            </>
          )}
          {row.TESTCODE !== "3612" && (
            <>
              <button
                title="Comparable Report"
                className="btn btn-success"
                onClick={() => getResults(row, true)}
                id={row.ID}
              >
                <FaExchangeAlt />
              </button>
              <button
                title="Report"
                className="btn btn-success m-2"
                onClick={() => getResults(row)}
                id={row.ID}
              >
                <FaExternalLinkAlt />
              </button>
            </>
          )}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  // console.log(emps);
  return (
    <>
      <Header Patient={Patient} view="tests" />
      <DataTable columns={columns} data={tests} />
      <Footer />
      <p className="text-center m-5">
        <button className="btn btn-danger" onClick={() => onTestReset([])}>
          Cancel
        </button>
      </p>
    </>
  );
}

export default Tests;
