import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

function TestResults({ results, onResultsReset, Patient, Test }) {
  const columns = [
    {
      name: "Parameter Name",
      selector: (row) => row.PARAMETERNAME,
      sortable: true,
    },
    {
      name: "Result",
      selector: (row) => row.PARAMETERRESULT,
      sortable: false,
    },
    {
      name: "Units",
      selector: (row) => row.UNITS,
      sortable: true,
    },
    {
      name: "Normal",
      selector: (row) => row.NORMAL_RANGE,
      sortable: true,
    },
    {
      cell: (row) => (
        <Link
          className="btn btn-success"
          to={`/test/${row.TESTID}/result/${row.TESTCODE}/print`}
        >
          Print
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  // console.log(emps);
  return (
    <>
      <div className="mx-auto m-5">
        <Header Patient={Patient} Test={Test} />
        <DataTable columns={columns} data={results} />
        <Footer />
      </div>

      <p className="text-center m-5">
        <button className="btn btn-info" onClick={() => onResultsReset([])}>
          Back
        </button>
      </p>
    </>
  );
}

export default TestResults;
