import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { thi_timestamp, thi_date } from "./../../services/Helper";

function AllTestResults({ results, oldResults }) {
  const [TableDates, setTableDates] = useState([]);
  const [TableResults, setTableResults] = useState([]);
  useEffect(() => {
    // console.log(oldResults);
    const totalParams = Object.values(results).length;
    const resultHistory = 3; // last results
    const comparedResults = Object.values(results).map((a, i) => {
      var old = oldResults.filter(
        (o) =>
          o.TESTID === a[0].TESTID ||
          thi_timestamp(o.DOTEST) < thi_timestamp(a[0].DOTEST)
      );

      // limit last three results
      old = old.slice(-(resultHistory * totalParams));
      old = old.reverse();
      old = old.filter((o) => a[0]["PARAMETERCODE"] === o.PARAMETERCODE);
      // getting dates for table heading
      var dates = old.map((o) => o.DOTEST);
      // getting RESULTS for table heading
      var results = old.map((o) => o.PARAMETERRESULT);

      // var lowervalue = old.map((o) => o.LOWERVALUE);
      // console.log(lowervalue);
      // removing nulled values
      dates = dates.filter((d) => d);
      // results = results.filter((r) => r);
      // console.log(results);
      setTableDates(dates);
      a[0]["RESULTS"] = results;
      // console.log(dates);
      return a;
    });
    setTableResults(comparedResults);
    // console.log(comparedResults);
  }, [oldResults, results]);

  // console.log(TableResults);

  const getFormattedResult = (result, lower, upper) => {
    if (result === null) return result;
    result = parseInt(result);
    if (result <= lower || result >= upper) {
      return <p className="fw-bold text-danger">{result}</p>;
    } else {
      return result;
    }
  };
  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <td className="td-name">Name</td>
          {TableDates.map((t) => (
            <td className="results">
              <small>{thi_date(t)}</small>
            </td>
          ))}

          <td className="others">Normal</td>
          <td className="others">Unit</td>
        </tr>
      </thead>
      <tbody>
        {Object.values(TableResults).map((r, i) => {
          const {
            PARAMETERNAME,
            RESULTS,
            NORMAL_RANGE,
            UNITS,
            LOWERVALUE,
            UPPERVALUE,
          } = r[0];
          // const pc = oldResults.filter(
          //   (o) => o.PARAMETERCODE === PARAMETERCODE
          // );
          // console.log(pc);
          return (
            <tr key={i}>
              <td>{PARAMETERNAME}</td>

              {RESULTS.map((r) => (
                <td>{getFormattedResult(r, LOWERVALUE, UPPERVALUE)}</td>
              ))}
              <td>{NORMAL_RANGE}</td>
              <td>{UNITS}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default AllTestResults;
