import DataTable from "react-data-table-component";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { thi_date } from "./../services/Helper";

function TestResultsComparable({ results, onResultsReset, Patient, Test }) {
  const columns = [
    {
      name: "Parameter Name",
      selector: (row) => row.PARAMETERNAME,
      sortable: true,
    },
    {
      name: "Result0",
      selector: (row) => row.RESULT0,
      sortable: false,
    },
    {
      name: "Result1",
      selector: (row) => row.RESULT1,
      sortable: false,
    },
    {
      name: "Result2",
      selector: (row) => row.RESULT2,
      sortable: false,
    },
    {
      name: "Units",
      selector: (row) => row.UNITS,
      sortable: true,
    },
    {
      name: "Normal",
      selector: (row) => row.NORMAL_RANGE,
      sortable: true,
    },
    // {
    //   cell: (row) => (
    //     <Link
    //       className="btn btn-success"
    //       to={`/test/${row.TESTID}/result/${row.TESTCODE}/print`}
    //     >
    //       Print
    //     </Link>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
  ];

  console.log(results[0]);
  var columnsNew = columns.map((c) => {
    c.name =
      c.name === "Result0" ? (
        <small>
          <strong>{thi_date(results[0]["DATE0"])}</strong>
        </small>
      ) : (
        c.name
      );
    c.name =
      c.name === "Result1" ? (
        <small>
          <strong>{thi_date(results[0]["DATE1"])}</strong>
        </small>
      ) : (
        c.name
      );
    c.name =
      c.name === "Result2" ? (
        <small>
          <strong>{thi_date(results[0]["DATE2"])}</strong>
        </small>
      ) : (
        c.name
      );
    return c;
  });
  return (
    <>
      <div className="mx-auto m-5">
        <Header Patient={Patient} Test={Test} />
        <DataTable columns={columnsNew} data={results} />
        <Footer />
      </div>

      <p className="text-center m-5">
        <button className="btn btn-info" onClick={() => onResultsReset([])}>
          Back
        </button>
      </p>
    </>
  );
}

export default TestResultsComparable;
