import { useState, useEffect } from "react";
import logo from "./../assets/thi-logo.jpg";
import QRCode from "qrcode";
import { getTestResultsPCR } from "../services/Model";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { thi_date } from "./../services/Helper";

function PCRResult() {
  const [QRCodeData, setQRCode] = useState([]);
  const [results, setTestResults] = useState([]);
  const [patient, setPatient] = useState([]);
  const [specimen, setSpecimen] = useState([]);
  const [master, setMaster] = useState([]);
  const [travel, setTravel] = useState([]);

  let { pid, tid } = useParams();

  const logo_w = 100;
  const generateQR = async (text) => {
    try {
      // console.log(await QRCode.toDataURL(text));
      setQRCode(await QRCode.toDataURL(text));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const qrinfo = `http://tahirheart.org/report/pcr/${pid}/${tid}`;
    generateQR(qrinfo);

    async function fetchPCRResults() {
      try {
        const tcode = 3612;

        let resultsFound = await getTestResultsPCR(pid, tid, tcode);
        resultsFound = resultsFound.data;
        // console.log(resultsFound);
        setTestResults(resultsFound.result);
        setPatient(resultsFound.patient);
        resultsFound.specimen && setSpecimen(resultsFound.specimen);
        resultsFound.master && setMaster(resultsFound.master);
        resultsFound.travel && setTravel(resultsFound.travel);
      } catch (err) {
        if (err.response && err.response.status === 400) {
          toast.error(err.response.data);
        }
      }
    }

    fetchPCRResults();
  }, [pid, tid]);

  const formatDate = (d) => {
    let c = new Date(d);
    return `${c.getDay()}-${c.getMonth()}-${c.getFullYear()}`;
  };

  const printPage = () => {
    //Get the print button and put it into a variable
    var printButton = document.getElementById("printpagebutton");
    //Set the print button visibility to 'hidden'
    printButton.style.visibility = "hidden";
    //Print the page content
    window.print();
    printButton.style.visibility = "visible";
  };

  const getSpecimenLabe = (id) => {
    const specimenLabels = {
      N_PNGEAL_O_PNGEAL_SWAB: "Nasopharyngeal Swab",
      BRONCHOALVEOLAR_LAVAGE: "Bronchoalveolar Lavage",
      ENDOTRACHEAL_ASPIRATES: "Endotracheal Aspirates",
      NASOPHARYNGEAL_ASPIRATES: "Nasopharyngeal Aspirates",
      NASAL_WASH: "Nasal Wash",
      SPUTUM: "Sputum",
      WHOLE_BLOOD: "Whole Blood",
      URINE: "Urine",
      STOOL: "Stool",
      OTHER: "Other",
    };

    return specimenLabels[id];
  };

  const result_color =
    results.PARAMETERRESULT === "Negative (Not-Detected)"
      ? "fs-5 text-success"
      : "fs-5 text-danger";

  return (
    <div className="pcr-wrapper">
      <Helmet>
        <title>THI Lab - PCR Report for {pid}</title>
      </Helmet>
      <div className="text-center"></div>
      <div className="row text-center">
        <div className="col-3">
          <img src={logo} alt="THI Logo" width={logo_w} />
        </div>
        <div className="col-6">
          <h3>TAHIR HEART INSTITUTE COVID PCR LAB</h3>
          <button
            id="printpagebutton"
            className="btn btn-primary"
            onClick={() => printPage()}
          >
            Print
          </button>
        </div>
        <div className="col-3">
          <img src={QRCodeData} alt="QRCode" width={logo_w} />
        </div>
      </div>

      <div className="row mt-1">
        <div className="card col-3">
          <p className="p-0">
            <span className="fw-bold">PID: </span>
            <span>{pid}</span>
          </p>
        </div>
        <div className="card col-6">
          <p>
            <span className="fw-bold">P.Name: </span>
            <span className="text-capitalize">{patient.NAME}</span>
          </p>
        </div>
        <div className="card col-3">
          <p>
            <span className="fw-bold">PHC Reg#: </span>
            <span>R-00785</span>
          </p>
        </div>
        <div className="card col-3">
          <p>
            <span className="fw-bold">Age: </span>
            <span>{patient.AGE}</span>
          </p>
        </div>
        <div className="card col-6">
          <p>
            <span className="fw-bold">CNIC: </span>
            <span>{patient.NIC}</span>
          </p>
        </div>
        <div className="card col-3">
          <p>
            <span className="fw-bold">Gender: </span>
            <span className="text-capitalize">{patient.GENDER}</span>
          </p>
        </div>
      </div>
      <div className="row mt-1">
        <div className="card col-5">
          <p>
            <span className="fw-bold">Collection Center: </span>
            <span>THI Lab</span>
          </p>
        </div>
        <div className="card col-7">
          <p>
            <span className="fw-bold">Sample Date: </span>
            <span className="text-capitalize">{thi_date(master.TOTEST)}</span>
          </p>
        </div>
        <div className="card col-5">
          <p>
            <span className="fw-bold">Specimen: </span>
            <span>{getSpecimenLabe(specimen.toString())}</span>
          </p>
        </div>
        <div className="card col-7">
          <p>
            <span className="fw-bold">Result Date: </span>
            <span className="text-capitalize">
              {thi_date(master.RESULTDATE)}
            </span>
          </p>
        </div>
      </div>
      {travel.length !== 0 && (
        <div className="row mt-1">
          <div className="card col-4">
            <p>
              <span className="fw-bold">Ticket#: </span>
              <span>{travel.TICKETNO}</span>
            </p>
          </div>
          <div className="card col-4">
            <p>
              <span className="fw-bold">Ticket Date: </span>
              <span className="text-capitalize">
                {formatDate(travel.DOTICKET)}
              </span>
            </p>
          </div>
          <div className="card col-4">
            <p>
              <span className="fw-bold">Flight#: </span>
              <span className="text-capitalize">{travel.FLIGHTNO}</span>
            </p>
          </div>
          <div className="card col-4">
            <p>
              <span className="fw-bold">Airline: </span>
              <span>{travel.AIRLINE}</span>
            </p>
          </div>
          <div className="card col-4">
            <p>
              <span className="fw-bold">Destination: </span>
              <span className="text-capitalize">{travel.DESTINATION}</span>
            </p>
          </div>
          <div className="card col-4">
            <p>
              <span className="fw-bold">Passport#: </span>
              <span>{travel.PASSPORTNO}</span>
            </p>
          </div>
        </div>
      )}
      <div className="card row mt-1 border border-dark">
        <div className="card-header text-center h5 text-white bg-dark">
          LABORATORY REPORT (COVID-19 PCR Qualitative)
        </div>
        <div className="card-body pcr-font-14">
          <div>
            <p>
              <span className="fw-bold h5">Result: </span>
              <span className={result_color}>{results.PARAMETERRESULT}</span>
            </p>
            <p>
              <span className="fw-bold h5">Report Interpretation: </span>
              <span
                dangerouslySetInnerHTML={{
                  __html: master.REPORT_INTERPRETATION,
                }}
              ></span>
            </p>
            <p>
              <span className="fw-bold h5">Methodologies: </span>
              <span
                dangerouslySetInnerHTML={{ __html: master.METHODOLOGY }}
              ></span>
            </p>
            <p>
              <span className="fw-bold h5">Remarks: </span>
              <span dangerouslySetInnerHTML={{ __html: master.REMARKS }}></span>
            </p>
          </div>
        </div>
      </div>
      <div className="card row mt-2 pcr-font-14 border border-dark">
        <div className="card-header text-center">
          This is a verified computer generated report. It does not require any
          signature or stamp.
        </div>
        <div className="row">
          <div className="col-6 text-center">
            <p className="mt-0">
              <strong>Dr. Bushra Tehreem</strong>
              <br />
              MBBS, DCP
              <br />
              Clinical Pathologist
            </p>
          </div>
          <div className="col-6 text-center">
            <p className="mt-0">
              <strong>Hafiz Jarriullah</strong>
              <br />
              MS Biochemistry & Molecular Biology
              <br />
              Molecular Biologist
            </p>
          </div>

          <p className="text-center">
            <strong>PCR Lab and Collection Center</strong>
            <br />
            Tahir Heart Institute Aqsa Rd. Chenab Nagar, Chiniot, Punjab,
            Pakistan
          </p>

          <div className="col-4 text-center">
            <p>Call: 047 6216010</p>
          </div>
          <div className="col-4 text-center">
            <p>Website: tahirheart.org/lab</p>
          </div>
          <div className="col-4 text-center">
            <p>Email: info@tahirheart.org</p>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default PCRResult;
