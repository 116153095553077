import http from "./Http";
import config from "./../config.json";

export function getTests(pid, tid) {
  return http.get(`${config.api_url}/test/${pid}/${tid}`);
}

export function getAllTests(pid, tid) {
  return http.get(`${config.api_url}/all-test/${pid}/${tid}`);
}

export function getTestResultsPCR(pid, tid, tcode) {
  return http.get(`${config.api_url}/test/${pid}/${tid}/result/${tcode}`);
}

export function getTestResults(tid, tcode) {
  return http.get(`${config.api_url}/test/${tid}/result/${tcode}`);
}

export function getComparableResults(pid, tcode) {
  return http.get(`${config.api_url}/test/${pid}/${tcode}/comparable`);
}

export function getComparableResultsAll(pid) {
  return http.get(`${config.api_url}/test/${pid}/comparable/all`);
}
