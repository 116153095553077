import { useState, useEffect } from "react";
import { thi_date } from "../services/Helper";

const Header = ({ Patient, Test }) => {
  const [TestName, setTestName] = useState("");
  useEffect(() => {
    const t = undefined !== Test ? Test.TESTNAME : TestName;
    setTestName(t);
  }, []);
  const getTitle = () => {
    const relation = Patient.GENDER === "M" ? "S/O" : "D/O";
    return `${Patient.NAME} ${relation} ${Patient.FATHERSNAME}`;
  };

  return (
    <div className="text-center m-3">
      {TestName && (
        <p>
          <strong>{TestName}</strong>
        </p>
      )}
      <p>
        <strong>{getTitle()}</strong>
      </p>
      <p>{thi_date(Patient.DOTEST)}</p>
    </div>
  );
};

export default Header;
