export const thi_date = (t) => {
  const tt = new Date(t);
  return tt.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  });
};

export const thi_timestamp = (strDate) => {
  const dt = Date.parse(strDate);
  return dt / 1000;
};
