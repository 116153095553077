import "./App.css";
import React from "react";
import { Route, Routes, useLocation, Navigate, Outlet } from "react-router-dom";
import { isLoggedin } from "./services/Auth";
import Navbar from "./components/Navbar";
import RequestReport from "./pages/RequestReport";
// import Home from "./pages/Home";
import PCRResult from "./pages/PCRResults";
import AllReports from "./pages/AllTests/AllReports";
import AllRequest from "./pages/AllTests/AllRequest";

// eslint-disable-next-line no-unused-vars
function RequireAuth() {
  let location = useLocation();

  if (!isLoggedin()) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
}

function App() {
  const location = useLocation();
  const isPrinting = () => {
    const thepath = location.pathname;
    console.log(thepath);
    let result =
      thepath.includes("report/pcr") || thepath.includes("/report/all/print");
    return result;
  };

  return (
    <>
      {!isPrinting() && <Navbar />}
      <div className="container-fluid">
        <Routes>
          {/* <Route element={<RequireAuth />}>
            <Route path="/not" element={<Roles />} />
          </Route> */}
          <Route path="/report" element={<RequestReport />} />

          <Route
            path="/report/all/print/:pid/:tid"
            element={<AllReports print={true} />}
          />
          <Route path="/report/all/:pid/:tid" element={<AllReports />} />
          <Route path="/report/all" element={<AllRequest />} />
          <Route path="/report/pcr/:pid/:tid" element={<PCRResult />} />
          <Route path="/" element={<RequestReport />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
