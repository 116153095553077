import { useState } from "react";
import { getTests } from "./../services/Model";
import { ToastContainer, toast } from "react-toastify";
import { Button, Spinner } from "react-bootstrap";

function TestForm({ setTests }) {
  const [patientID, setPID] = useState("");
  const [testID, setTID] = useState("");
  let [loading, setLoading] = useState(false);
  let [searchText, setSearchTest] = useState("Search");

  const getPatientTests = async () => {
    setLoading(!loading);
    setSearchTest("Searching");

    try {
      let testsFound = await getTests(patientID, testID);
      testsFound = testsFound.data.rows;
      console.log(testsFound);
      setTests(testsFound);
      setLoading(false);
      toast.success(`${testsFound.length} Tests found`);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        toast.error(err.response.data);
        setLoading(false);
      }
    }
  };

  return (
    <div className="mx-auto text-center thi-report-wrapper sweet-loading">
      <h2>Enter Patient/Test Information</h2>
      <p>
        <input
          onChange={(e) => setPID(e.target.value)}
          type="text"
          className="form-control form-control-lg"
          placeholder="Username"
        />
      </p>
      <p>
        <input
          onChange={(e) => setTID(e.target.value)}
          type="text"
          className="form-control form-control-lg"
          placeholder="Password"
        />
      </p>
      <Button
        onClick={() => getPatientTests()}
        variant="primary"
        className="thi-report-button"
      >
        {loading && (
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        {searchText}
      </Button>

      <ToastContainer />
    </div>
  );
}

export default TestForm;
