import { useEffect, useState } from "react";
import { getTestResults, getComparableResults } from "../services/Model";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TestForm from "./TestForm";
import Tests from "./Tests";
import TestResults from "./TestResults";
import TestResultsComparable from "./TestResultsCompare";
import { Helmet } from "react-helmet";

function RequestReport() {
  const [tests, setTests] = useState([]);
  const [Test, setTest] = useState({});
  const [Patient, setPatient] = useState({});
  const [results, setTestResults] = useState([]);
  const [enableCompare, setEnableCompare] = useState(true);
  const [resultsComparable, setTestResultsComparable] = useState([]);
  const [HideForm, setHideForm] = useState(false);

  useEffect(() => {
    const h = tests.length > 0 ? true : false;
    setPatient(tests[0]);
    setHideForm(h);
  }, [tests, Patient]);

  async function getResults(test, isComparable = false) {
    try {
      setTest(test);
      setEnableCompare(isComparable);
      let resultsFound = await getTestResults(test.TESTID, test.TESTCODE);
      resultsFound = resultsFound.data.rows;

      // filtering test results for paramerts based on gender
      resultsFound = resultsFound.filter(
        (r) =>
          (r.GENDER === "N" || r.GENDER === test.GENDER) &&
          (r.AGERANGE === "N" || r.AGERANGE === "A")
      );

      // Adding Comarable Results
      getUpdatedResults(
        isComparable,
        test,
        resultsFound,
        function (updatedResults) {
          // console.log(updatedResults);
          setTestResults(updatedResults);
        }
      );
    } catch (err) {
      if (err.response && err.response.status === 400) {
        toast.error(err.response.data);
      }
    }
  }

  const getUpdatedResults = async (
    isComparable,
    test,
    resultsFound,
    callback
  ) => {
    console.log(isComparable);
    if (!isComparable) return callback(resultsFound);

    const oldResults = await getResultsComparable(test.PID, test.TESTCODE);
    setTestResultsComparable(oldResults);
    const comparedResults = resultsFound.map((a, i) => {
      var params = getTestParams(oldResults, a.PARAMETERCODE);
      console.log(params);
      params.reverse();
      a = { ...a, ...params[0], ...params[1], ...params[2] };
      return a;
    });

    return callback(comparedResults);
  };

  const getResultsComparable = async (pid, testcode) => {
    try {
      let r = await getComparableResults(pid, testcode);

      const result = r.data.rows.reduce(function (r, a) {
        r[a.TESTID] = r[a.TESTID] || [];
        r[a.TESTID].push(a);
        return r;
      }, Object.create(null));

      return objSlice(result, 3);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        toast.error(err.response.data);
      }
    }
  };

  const getTestParams = (oldResults, paramcode) => {
    var filtered = Object.values(oldResults).map((a) =>
      a.filter((b) => b.PARAMETERCODE === paramcode)
    );

    return filtered.map((c, i) => {
      return {
        [`DATE${i}`]: c[0].DOTEST,
        [`RESULT${i}`]: c[0].PARAMETERRESULT,
      };
      // return { DATE: c[0].DOTEST, RESULT: c[0].PARAMETERRESULT };
    });
  };

  function objSlice(obj, lastExclusive) {
    var filteredKeys = Object.keys(obj).slice(-lastExclusive);
    var newObj = {};
    filteredKeys.forEach(function (key) {
      newObj[key] = obj[key];
    });
    return newObj;
  }

  return (
    <div>
      <Helmet>
        <title>THI Lab Online</title>
      </Helmet>

      {results.length === 0 && (
        <>
          {!HideForm ? (
            <TestForm setTests={setTests} />
          ) : (
            <Tests
              Test={Test}
              tests={tests}
              getResults={getResults}
              onTestReset={setTests}
              Patient={Patient}
            />
          )}
        </>
      )}
      {results.length > 0 &&
        (!enableCompare ? (
          <TestResults
            Test={Test}
            results={results}
            onResultsReset={setTestResults}
            Patient={Patient}
          />
        ) : (
          <TestResultsComparable
            Test={Test}
            results={results}
            onResultsReset={setTestResults}
            Patient={Patient}
          />
        ))}
    </div>
  );
}

export default RequestReport;
