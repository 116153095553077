const TestFooter = () => {
  return (
    <div className="thi-footer text-center">
      <hr />
      <p>+92 47 6216101-12 - www.tahirheart.org - info@tahirheart.org</p>
    </div>
  );
};

export default TestFooter;
