const Footer = () => {
  return (
    <div className="thi-footer text-center">
      <hr />
      <p>
        <strong>
          <small>TAHIR HEART INSTITUTE, CHENAB NAGAR</small>
        </strong>
      </p>
      <p>+92 47 6216101-12 - www.tahirheart.org - info@tahirheart.org</p>
    </div>
  );
};

export default Footer;
