import { thi_date } from "../../services/Helper";
import logo from "./../../assets/thi-logo.jpg";
import QRCode from "qrcode";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import config from "./../../config.json";

const TestHeader = ({ header, print, pid }) => {
  const [QRCodeData, setQRCode] = useState([]);

  const logo_w = 100;
  const generateQR = async (text) => {
    try {
      // console.log(await QRCode.toDataURL(text));
      setQRCode(await QRCode.toDataURL(text));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const qrinfo = `http://tahirheart.org/report/all/${pid}/${header.TESTID}`;
    generateQR(qrinfo);
  }, [header, pid]);

  const ts = Math.floor(Date.now() / 1000);
  let PDFURL = `${config.api_url}/pdf?url=${config.front_url}/report/all/print/${pid}/${header.TESTID}&t=${ts}`;

  const getTitle = () => {
    const relation = header.GENDER === "M" ? "S/O" : "D/O";
    return `${header.NAME} ${relation} ${header.FATHERSNAME}`;
  };
  return (
    <>
      {!print && (
        <p className="text-center m-3">
          <button
            title="Report"
            className="btn btn-success m-1"
            onClick={() => window.open(PDFURL, "_blank")}
          >
            Print
          </button>
        </p>
      )}
      <div className="row">
        <div className="col-sm-3 text-center">
          <img src={logo} alt="THI Logo" width={logo_w} />
        </div>
        <div className="col-sm-6 text-center mt-2">
          <p>
            <strong>TAHIR HEART INSTITUTE, CHENAB NAGAR</strong>
          </p>
          <p>
            <strong>{getTitle()}</strong>
          </p>
          <p>{thi_date(header.DOTEST)}</p>
        </div>
        <div className="col-sm-3 text-center">
          <img src={QRCodeData} alt="QRCode" width={logo_w} />
        </div>
      </div>
    </>
  );
};

export default TestHeader;
