import { useState } from "react";
import Helmet from "react-helmet";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function AllRequest() {
  const [patientID, setPID] = useState("");
  const [testID, setTID] = useState("");
  const navigate = useNavigate();

  const getPatientTests = async () => {
    const path = `/report/all/${patientID}/${testID}`;
    navigate(path);
  };
  return (
    <div>
      <Helmet>
        <title>THI Lab Online</title>
      </Helmet>

      <div className="mx-auto text-center thi-report-wrapper sweet-loading">
        <h2>Enter Patient/Test Information</h2>
        <p>
          <input
            onChange={(e) => setPID(e.target.value)}
            type="text"
            className="form-control form-control-lg"
            placeholder="Username"
          />
        </p>
        <p>
          <input
            onChange={(e) => setTID(e.target.value)}
            type="text"
            className="form-control form-control-lg"
            placeholder="Password"
          />
        </p>
        <Button
          onClick={() => getPatientTests()}
          variant="primary"
          className="thi-report-button"
        >
          Search
        </Button>
      </div>
    </div>
  );
}

export default AllRequest;
