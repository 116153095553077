import { useEffect, useState } from "react";
import { getAllTests, getComparableResultsAll } from "../../services/Model";
import TestHeader from "./TestHeaders";
import TestFooter from "./TestFooter";
import AllTestResults from "./AllResults";
// import TestResults from "./TestResults";
// import TestResultsComparable from "./TestResultsCompare";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const AllReports = ({ print }) => {
  const [Tests, setTests] = useState([]);
  const [AllTests, setAllTests] = useState();
  const [HeadInfo, setHeadInfo] = useState();
  const { pid, tid } = useParams();

  useEffect(() => {
    const fetchAllTests = async () => {
      var test_res = await getAllTests(pid, tid);

      // console.log(test_res);
      // Filtering Results based on Gender
      test_res = test_res.data.rows.filter(
        (tr) =>
          (tr.RESULT_GENDER === "N" || tr.RESULT_GENDER === tr.GENDER) &&
          (tr.AGERANGE === "N" || tr.AGERANGE === "A")
      );

      const result = test_res.reduce(function (r, a) {
        r[a.TESTCODE] = r[a.TESTCODE] || [];
        r[a.TESTCODE].push(a);
        return r;
      }, Object.create(null));

      // console.log(result);

      const tests = Object.values(result).map((r) => {
        var rr2 = [];
        rr2["TESTNAME"] = r[0]["TESTNAME"];
        rr2["TESTCODE"] = r[0]["TESTCODE"];
        rr2["PINFO"] = Object.keys(r[0])
          .filter(
            (key) =>
              key.includes("NAME") ||
              key.includes("PID") ||
              key.includes("GENDER") ||
              key.includes("TEST")
          )
          .reduce((cur, key) => {
            return Object.assign(cur, { [key]: r[0][key] });
          }, {});
        rr2["RESULT"] = r.reduce(function (r, a) {
          r[a.PARAMETERCODE] = r[a.PARAMETERCODE] || [];
          r[a.PARAMETERCODE].push(a);
          return r;
        }, Object.create(null));
        return rr2;
      });

      // console.log(tests);
      setHeadInfo(tests[0]["PINFO"]);
      setTests(tests);
      fetchAllComparable();
    };
    fetchAllTests();

    const fetchAllComparable = async () => {
      const c = await getComparableResultsAll(pid);
      // console.log(c.data.rows);
      setAllTests(c.data.rows);
    };
  }, [pid, tid]);

  // console.log(HeadInfo);
  return (
    <div>
      <Helmet>
        <title>THI Lab Online - Test Results</title>
      </Helmet>
      {HeadInfo && <TestHeader header={HeadInfo} print={print} pid={pid} />}
      {Tests.map((t, i) => {
        return (
          <div className="thi-wrapper" key={i}>
            <p className="text-center">
              <strong>{t["TESTNAME"]}</strong>
            </p>
            {AllTests && (
              <AllTestResults
                results={t["RESULT"]}
                oldResults={AllTests.filter(
                  (a) =>
                    a.TESTCODE === t["TESTCODE"] &&
                    (a.RESULT_GENDER === "N" ||
                      a.RESULT_GENDER === t["PINFO"].GENDER) &&
                    (a.AGERANGE === "N" || a.AGERANGE === "A")
                )}
              />
            )}
          </div>
        );
      })}
      <TestFooter />
    </div>
  );
};

export default AllReports;
